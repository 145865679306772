.linktree-ul {
  position: fixed;
  top: 75%;
  left: 0;
  right: 0;
  z-index: 999;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}