html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    font-size: $m-size;
    background: white;
    width: 100%;
    height: 100%;
}

button {
    cursor: pointer
}

button:disabled {
    cursor: default
}

.is-active {
  font-weight: bold;
}

.top-left-logo {
  top: 10px;
  left: 10px;
  background-position: center;
  background-repeat: no-repeat;
  //display: flex;
  position: fixed;
  //margin: (10, 0, 0, 10);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(#{$imagesPath}/logo_full_rag.svg);
  width: 20%;
  height: 15%;
  //border: 1px solid black;
}