// --------------------------
// Hero Image section
// --------------------------
.hero-image-maintenance-wrapper {
  //background-color: #000000;
  //min-width: 100vw;
  //min-height: 100vh;
}

.hero-image-maintenance {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(#{$imagesPath}/construction.svg);
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  height: 35%;
  z-index: 999;
}