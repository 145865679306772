.job-li {
  font: 200 20px/1.5 Helvetica, Verdana, sans-serif;
  border-bottom: 1px solid #ccc;
  display: flex;
  cursor: pointer;
  background-color: white;
  align-items: flex-start;
}

.job-li div {
  display: block;
}

.job-li:hover {
  background-color: lightblue;
}

.job-li h3 {
  margin: 10px 20px;
  border-color: green;
  border-width: 5px;
  border-style: solid;
}

.job-li p {
  line-height: 2em;
  margin: 10px 20px;
  overflow: hidden;
  transition: height 0.4s ease-out;
  opacity: 0;
  height: 0;
}

.job-li p.show {
  height: 2em;
  margin: 10px 20px;
  opacity: 1;
}