.linktree-li {
  display: inline;

  padding: 10px;
  margin: 10px;
}

.linktree-li-link {
  text-decoration: none;
  transition: all .2s ease-in-out;
}

.linktree-li-link:hover {
  transform: scale(1.5, 1.5);
}

.linktree-ul-link-fa-instagram {
  display: inline-block;
  size: 2.25em;
  text-align: center;
  border-radius: 7.5px;
  color: #fff;
  vertical-align: middle;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
}