// vars
$assetPath : "/assets";
$imagesPath : "#{$assetPath}/images";

// functions
@function get-path-to-assets($assetPath){
  @return $assetPath;
}

// colors

// sizes
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;